import { navigate } from "gatsby"
import { useQueryParam, StringParam } from 'use-query-params'

export const usePageViewState = () => {
  const [ params, setParams ] = useQueryParam("view", StringParam)

  return {
    viewState: {
      isFaq: params === 'faq',
      isVideo: params === 'video',
      isVisibile: params === 'faq' || params === 'video',
    },
    openVideo: () => navigate('?view=video'),
    openFaq: () => navigate('?view=faq'),
    returnHome: () => setParams(undefined),
  }
}
