import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { primary } from 'src/styles/newColors'
import * as S from './styles'
import { Button } from '@interco/inter-ui/components/Button'

type CardWithLinkProps = {
  icon: string;
  title: string;
  onButtonClick: () => void;
}

const CardWithButton: React.FC<CardWithLinkProps> = ({ icon, title, onButtonClick }: CardWithLinkProps) => {
  return (
    <S.CardWithButton>
      <IconsSwitch className='card__icon' icon={icon} color='' lib='interco' size='LG' />
      <h3 className='card__title'>{title}</h3>
      <Button type='button' className='card__button' onClick={onButtonClick} fullWidth variant='secondary'>
        Conhecer
        <IconsSwitch lib='interco' icon='action-navigation/ic_chevron_right' size='LG' color={primary[500]} />
      </Button>
    </S.CardWithButton>
  )
}

export default CardWithButton
