import React, { useCallback, useEffect } from 'react'
import faqData from '../data/tesouro-me-poupe-app-faq.json'
import { Accordion } from '@interco/inter-ui/components/Accordion'
import { initTheme, Theme } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import * as S from './styles'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { primary } from 'src/styles/newColors'
import { tagAccordionClick, tagTermsAndConditionsClick } from '../tagHelpers/_index'
import { termsUrl } from '../_constants'

const TesouroMePoupeAppFAQ = () => {
  const [ expandedItems, setExpandedItems ] = React.useState<number[]>([])

  useEffect(() => {
    initTheme(Theme.PF)
  })

  const handleAccordionClick = useCallback((item: { question: string; answer: string }, index: number) => {
    setExpandedItems(expandedItems.includes(index) ? expandedItems.filter((i: number) => i !== index) : [ ...expandedItems, index ])
    tagAccordionClick(item.question)
  }, [ expandedItems ])

  const handleButtonClick = async () => {
    tagTermsAndConditionsClick()
  }
  return (
    <S.ConstruaSeuTesoursoFAQ>
      <ul>
        {faqData.map((item: { question: string; answer: string }, index: number) => (
          <li key={index}>
            <Accordion
              title={item.question}
              expanded={expandedItems.includes(index)}
              onClick={() => handleAccordionClick(item, index)}
            >
              <div className='faq__body' dangerouslySetInnerHTML={{ __html: item.answer }} />
            </Accordion>
          </li>
        ))}
      </ul>
      <a href={termsUrl} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
        <Button fullWidth variant='secondary' className='terms-and-conditions-btn' type='button' onClick={handleButtonClick}>
          <IconsSwitch lib='interco' icon='files/ic_document' size='LG' color='' />
          <div className='terms-and-conditions-btn__text'>Termos e Condições</div>
          <IconsSwitch lib='interco' icon='action-navigation/ic_chevron_right' size='LG' color={primary[500]} />
        </Button>
      </a>
    </S.ConstruaSeuTesoursoFAQ>
  )
}

export default TesouroMePoupeAppFAQ
