import { TEXT_CONTENT } from "../_constants"
import { addEventToWindowDataLayer } from "src/hooks/useDataLayer/dataLayerBody"
import { Investment } from "../components/InvestmentsDisplay/_InvestmentsDisplay"

export const tagCardClick = (title: string) => {
  addEventToWindowDataLayer({
    section: TEXT_CONTENT.heading,
    section_name: title,
    element_action: 'click button',
    element_name: 'Conhecer',
    redirect_url: null,
  })
}

export const tagCtaClick = () => {
  addEventToWindowDataLayer({
    section: TEXT_CONTENT.heading,
    element_action: 'click button',
    element_name: TEXT_CONTENT.cta,
    redirect_url: null,
  })
}

export const tagVideoClick = () => {
  addEventToWindowDataLayer({
    section: TEXT_CONTENT.heading,
    element_action: 'click video',
    element_name: '4 passos práticos para aprender a investir no Tesouro Direto.',
    redirect_url: null,
  })
}

export const tagHelpClick = () => {
  addEventToWindowDataLayer({
    section: "Construa seu Tesouro",
    element_action: 'click button',
    element_name: 'Ajuda',
    redirect_url: null,
  })
}

export const tagBackClick = () => {
  addEventToWindowDataLayer({
    section: TEXT_CONTENT.heading,
    element_action: 'click button',
    element_name: 'Voltar',
    redirect_url: null,
  })
}

export const tagAccordionClick = (question: string) => {
  addEventToWindowDataLayer({
    section: "Construa seu tesouro",
    section_name: "Construa seu tesouro FAQ",
    element_action: 'click button',
    element_name: question,
    redirect_url: null,
  })
}

export const tagTermsAndConditionsClick = () => {
  addEventToWindowDataLayer({
    section: "Construa seu tesouro",
    section_name: "Construa seu tesouro FAQ",
    element_action: 'click button',
    element_name: 'Termos e Condições',
    redirect_url: null,
  })
}

export const tagBottomSheetClick = (investment: Investment) => {
  addEventToWindowDataLayer({
    section: TEXT_CONTENT.heading,
    section_name: investment.title,
    element_action: 'click button',
    element_name: investment.ctaText,
    redirect_url: null,
  })
}
